import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	useSuspenseQuery as useBaseSuspenseQuery,
	queryOptions,
} from "@tanstack/react-query";

import { PostGroup } from "~/enums";
import { type ParentBase } from "~/types/application";

import { getResourceCategories, type GetResourceCategoriesResponse, getUrl } from "./categories.api";
import { type ResourceCategoriesResponse } from "./categories.schema";

type ResourceCategoriesParent = ParentBase<PostGroup.RESOURCE_CATEGORIES, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [resourceCategories: ResourceCategoriesParent];

const getQueryKey = () => ["resource-categories"] as const;

const getBaseQueryOptions = <TData = GetResourceCategoriesResponse>() =>
	queryOptions<GetResourceCategoriesResponse, Error, TData>({
		queryFn: getResourceCategories,
		queryKey: getQueryKey(),
	});

const useSuspenseQuery = <TData = GetResourceCategoriesResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetResourceCategoriesResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> => {
	return useBaseSuspenseQuery<GetResourceCategoriesResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});
};

const getParent = () =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		type: PostGroup.RESOURCE_CATEGORIES,
	}) satisfies ResourceCategoriesParent;

export {
	useSuspenseQuery as useResourceCategoriesSuspenseQuery,
	getBaseQueryOptions as getResourceCategoriesBaseQueryOptions,
	getQueryKey as getResourceCategoriesQueryKey,
	getParent as getResourceCategoriesParent,
	getUrl as getResourceCategoriesUrl,
	type Tuple as ResourceCategoriesTuple,
	type ResourceCategoriesResponse,
};
