import { z } from "zod";

import { AuthType } from "~/enums";
import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheableMediaUrl";

export const profileSchema = z.object({
	acceptedPrivacyPolicy: z.boolean().nullable().catch(null),
	acceptedTermsConditions: z.boolean().nullable().catch(null),
	authType: z.nativeEnum(AuthType).catch(AuthType.INTERNAL),
	avatarUrl: cacheableMediaUrlSchema(true).nullable(),
	firstName: z.string().nullable(),
	id: z.number(),
	interests: z.array(z.string()).optional(),
	lastName: z.string().nullable(),
	name: z.string(),
	permissions: z
		.object({
			canEditOwnComments: z.boolean().catch(false),
		})
		.catch({ canEditOwnComments: false }),
});

export type ProfileResponse = z.infer<typeof profileSchema>;
