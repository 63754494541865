/**
 * Routes for the resources page.
 */
import { nprogress } from "@mantine/nprogress";
import { createRoute, lazyRouteComponent, useNavigate } from "@tanstack/react-router";
import { t } from "i18next";

import { getResourceCategoryBaseQueryOptions } from "~/lib/api/resources/category";
import { getResourceBaseQueryOptions } from "~/lib/api/resources/resource";

import { applicationLayout } from "./application";

export const resourceCategoriesRoute = createRoute({
	getParentRoute: () => applicationLayout,
	path: "resources",
	staticData: {
		crumb: () => t("navigation.resources"),
	},
});

export const resourceCategoriesIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Resources"), "Page"),
	getParentRoute: () => resourceCategoriesRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
});

export const resourcesCategoryRoute = createRoute({
	getParentRoute: () => resourceCategoriesRoute,
	loader: async ({ context, params }) => {
		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.resource-category.loading"),
		};

		try {
			const resourceCategory = await context.queryClient.ensureQueryData(
				getResourceCategoryBaseQueryOptions(parseInt(params.categoryId)),
			);
			context.breadcrumbs = {
				crumb: () => resourceCategory.title,
			};
		} catch (error) {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.resource-category.failure"),
			};
		}
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "$categoryId",
});

export const resourcesCategoryIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Resources/Category"), "Page"),
	getParentRoute: () => resourcesCategoryRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

export const resourceRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Resources/Resource"), "Page"),
	getParentRoute: () => resourcesCategoryRoute,
	loader: async ({ context, params }) => {
		nprogress.cleanup();

		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.resource.loading"),
		};

		try {
			const resource = await context.queryClient.ensureQueryData(
				getResourceBaseQueryOptions(parseInt(params.resourceId)),
			);
			context.breadcrumbs = {
				crumb: () => resource.title,
			};
		} catch (error) {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.resource.failure"),
			};
		}
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doens't recognise it
		const navigate = useNavigate();

		navigate({
			params: resourceRoute.useParams(),
			to: resourceRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "$resourceId",
});

export const resourceIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Resources/Resource"), "Page"),
	getParentRoute: () => resourceRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

export const directResourceCategoryRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => applicationLayout,
	loader: async ({ context, params }) => {
		nprogress.cleanup();

		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.resource-category.loading"),
		};

		try {
			const resourceCategory = await context.queryClient.ensureQueryData(
				getResourceCategoryBaseQueryOptions(parseInt(params.categoryId)),
			);
			context.breadcrumbs = {
				crumb: () => resourceCategory.title,
			};
		} catch (error) {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.resource-category.failure"),
			};
		}
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "category/$categoryId",
	pendingComponent: lazyRouteComponent(() => import("~/pages/Resources/Resources.pending"), "Pending"),
});

export const directResourceCategoryIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Resources/DirectCategory"), "Page"),
	getParentRoute: () => directResourceCategoryRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});

export const directResourceRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => applicationLayout,
	loader: async ({ context, params }) => {
		nprogress.cleanup();

		context.breadcrumbs = {
			crumb: () => t("breadcrumbs.resource.loading"),
		};

		try {
			const resource = await context.queryClient.ensureQueryData(
				getResourceBaseQueryOptions(parseInt(params.resourceId)),
			);
			context.breadcrumbs = {
				crumb: () => resource.title,
			};
		} catch (error) {
			context.breadcrumbs = {
				crumb: () => t("breadcrumbs.resource.failure"),
			};
		}
	},
	notFoundComponent: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks -- This is a component, but eslint doens't recognise it
		const navigate = useNavigate();

		navigate({
			params: directResourceRoute.useParams(),
			to: directResourceRoute.to,
		});

		return null;
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "resource/$resourceId",
	pendingComponent: lazyRouteComponent(() => import("~/pages/Resources/Resources.pending"), "Pending"),
});

export const directResourceIndexRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Resources/DirectResource"), "Page"),
	getParentRoute: () => directResourceRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "/",
});
