import { LANGUAGE_STORAGE_KEY_BASE } from "~/lib/constants/language";
import { generateHash } from "~/utilities/generate-hash";

/**
 * Generates the storage key for language preference based on userId
 * @param userId The user identifier
 * @returns The storage key for language preference
 * @example getLanguageStorageKey('user123');
 * // Output: `${LANGUAGE_STORAGE_KEY_BASE}:03ac674216f3e15c761ee1a5e255f067953623c8b388b4459e13f978d7c846f4`
 */
export const getLanguageStorageKey = async (userId: number): Promise<string> => {
	const keyHash = await generateHash(userId);
	return `${LANGUAGE_STORAGE_KEY_BASE}${keyHash}`;
};
