import { type AnyFunction } from "~/types/application";

const URL_BASE = "/wp-json/frontend" as const;

export type CheckPath<TPath extends string> = TPath extends `/${infer _}`
	? "Error: Path cannot start with a /"
	: TPath extends `v${number}/${infer _}`
		? "Error: Path cannot include a version identifier"
		: TPath;

export const buildUrl = <const TPath extends string, const TVersion extends number>(
	path: CheckPath<TPath> extends `Error: ${infer E}` ? E : TPath,
	version: TVersion,
): CheckPath<TPath> extends `Error: ${infer E}` ? E : `${typeof URL_BASE}/v${TVersion}/${TPath}` => {
	// @ts-expect-error -- we ignore the ts error here, as we are strictly checking for the path to not start with a / or include a version identifier
	return `${URL_BASE}/v${version}/${path}` as const;
};

export const buildUrlWithQuery = <
	const TParams extends Record<string, number | string | undefined>,
	const TPath extends string = string,
	const TVersion extends number = number,
>(
	path: CheckPath<TPath> extends `Error: ${infer E}` ? E : TPath,
	version: TVersion,
	queryParams?: TParams,
): TParams extends Record<string, string | number | boolean>
	? `${ReturnType<typeof buildUrl<TPath, TVersion>>}?${string}`
	: ReturnType<typeof buildUrl<TPath, TVersion>> => {
	const baseUrl = buildUrl<TPath, TVersion>(path, version);

	if (!queryParams) {
		// @ts-expect-error -- we ignore the ts error here, as we are strictly checking for the path to not start with a / or include a version identifier
		return baseUrl;
	}

	const params = new URLSearchParams();

	for (const [key, value] of Object.entries(queryParams)) {
		if (typeof value === "number" || typeof value === "string") {
			params.append(key, value.toString());
		} else if (typeof value === "undefined") {
			params.append(key, "");
		} else {
			throw new Error("Error: Query parameter must be a (number/string/undefined)");
		}
	}

	// @ts-expect-error -- we ignore the ts error here, as we are strictly checking for the path to not start with a / or include a version identifier
	return `${baseUrl}?${params.toString()}` as const;
};

type ArgumentTypes<F extends AnyFunction> = F extends (...args: infer A) => unknown ? A : never;

export type BuildUrlWithQueryParams<TT extends Record<string, number | undefined>> = ArgumentTypes<
	typeof buildUrlWithQuery<TT>
>[2];
