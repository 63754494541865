import { z } from "zod";

import { cacheableMediaUrlSchema } from "../schemas/cacheableMediaUrl";

export const featuredMediaSchema = z.object({
	//Allow null as they can have featuredMedia with no posterUrl
	posterUrl: cacheableMediaUrlSchema().nullable(),
	// Admin can determine what upload type is allowed, so we don't want to restrict this to specific types
	type: z.string().nullable(),
	url: cacheableMediaUrlSchema().nullable(),
});

export type FeaturedMedia = z.infer<typeof featuredMediaSchema>;
