import { z } from "zod";

import { sanitiseUnderscoreString } from "~/utilities/sanitise-underscore-string";

export const completedLearningSchema = z.object({
	count: z.number(),
	items: z.array(
		z.object({
			completedAt: z.string().datetime({ offset: true }),
			id: z.number(),
			title: z.string().transform(sanitiseUnderscoreString).nullable(),
			type: z.string(),
		}),
	),
});

export type CompletedLearningResponse = z.infer<typeof completedLearningSchema>;
