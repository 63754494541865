import { getCookie } from "@evotix/napier-ui-common-native-bridge/utilities";

import { TOKEN_COOKIE_NAME } from "~/lib/constants/application";

/**
 * Checks if the authentication cookie exists.
 * @returns {boolean} True if the authentication cookie exists, false otherwise.
 * @example
 * // Returns true if the authentication cookie exists
 * const result = hasAuthenticationCookie();
 */
export const hasAuthenticationCookie = () =>
	Boolean(getCookie(TOKEN_COOKIE_NAME)) && getCookie(TOKEN_COOKIE_NAME) !== "deleted";
