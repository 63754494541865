import { wretch } from "~/lib/external/wretch";

import { buildUrl } from "../../utilities";

import { type LatestResourcesResponse, latestResourcesSchema } from "./latest-resources.schema";

const getUrl = () => buildUrl(`resources/latest`, 1);

const getLatestResources = (): Promise<LatestResourcesResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl())
		.json(latestResourcesSchema.parseAsync);

type GetLatestResourcesResponse = Awaited<ReturnType<typeof getLatestResources>>;

export { getLatestResources, getUrl, type GetLatestResourcesResponse };
