import {
	useSuspenseQuery as useBaseSuspenseQuery,
	queryOptions,
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	type UseQueryOptions,
} from "@tanstack/react-query";

import { type LeaderboardKey } from "~/enums";

import { getTopLeaders, type GetTopLeadersResponse } from "./top-leaders.api";

const getQueryKey = (leaderboardKey: LeaderboardKey) => ["activities", "leaders", "top", leaderboardKey] as const;

const getBaseQueryOptions = <TData = GetTopLeadersResponse>(
	key: LeaderboardKey,
): UseQueryOptions<GetTopLeadersResponse, Error, TData> =>
	queryOptions<GetTopLeadersResponse, Error, TData>({
		queryFn: () => getTopLeaders(key),
		queryKey: getQueryKey(key),
	});

const useSuspenseQuery = <TData = GetTopLeadersResponse>(
	key: LeaderboardKey,
	options?: Pick<UseSuspenseQueryOptions<GetTopLeadersResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useBaseSuspenseQuery<GetTopLeadersResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(key),
	});

export {
	useSuspenseQuery as useLeaderboardTopSuspenseQuery,
	getQueryKey as getTopLeadersQueryKey,
	getTopLeaders,
	getBaseQueryOptions as getTopLeadersBaseQueryOptions,
};
