import {
	queryOptions,
	type UseQueryOptions,
	useSuspenseQuery as useBaseSuspenseQuery,
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
} from "@tanstack/react-query";

import { type LeaderboardKey } from "~/enums";
import { LIVE_QUERY_STALE_TIME } from "~/lib/constants/application";

import { getAllLeaders, type GetAllLeadersResponse } from "./all-leaders.api";

const getQueryKey = (leaderboardKey: LeaderboardKey) => ["activities", "leaders", "all", leaderboardKey] as const;

const getBaseQueryOptions = <TData = GetAllLeadersResponse>(
	key: LeaderboardKey,
): UseQueryOptions<GetAllLeadersResponse, Error, TData> =>
	queryOptions<GetAllLeadersResponse, Error, TData>({
		queryFn: () => getAllLeaders(key),
		queryKey: getQueryKey(key),
	});

const useSuspenseQueryQuery = <TData = GetAllLeadersResponse>(
	key: LeaderboardKey,
	options?: Pick<UseSuspenseQueryOptions<GetAllLeadersResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useBaseSuspenseQuery<GetAllLeadersResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(key),
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: LIVE_QUERY_STALE_TIME,
	});

export {
	useSuspenseQueryQuery as useLeaderboardAllSuspenseQuery,
	getAllLeaders,
	getQueryKey as getAllLeadersQueryKey,
	getBaseQueryOptions as getAllLeadersBaseQueryOptions,
};
