import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	queryOptions,
	useSuspenseQuery,
} from "@tanstack/react-query";

import { getStage, getStageUrlWithQueryParams, type GetStageResponse } from "./stage.api";
import { type StageResponse, type QuizData, type Question } from "./stage.schema";

type LearningQueryKey<TGroup extends string, TParams extends Record<string, number | undefined>> = [
	"learning",
	TGroup,
	TParams,
];

const getQueryKey = (
	id: number,
	missionId?: number,
): LearningQueryKey<"stage", { id: number; missionId: number | undefined }> => ["learning", "stage", { id, missionId }];

const getBaseQueryOptions = <TData = GetStageResponse>(id: number, missionId?: number) =>
	queryOptions<GetStageResponse, Error, TData>({
		queryFn: () => getStage(id, missionId),
		queryKey: getQueryKey(id, missionId),
	});

const useStageSuspenseQuery = <TData = GetStageResponse>(
	id: number,
	missionId?: number,
	options?: Pick<UseSuspenseQueryOptions<GetStageResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	useSuspenseQuery<GetStageResponse, Error, TData>({
		...getBaseQueryOptions(id, missionId),
		...options,
	});

export {
	useStageSuspenseQuery,
	getBaseQueryOptions as getStageBaseQueryOptions,
	getQueryKey as getStageQueryKey,
	getStageUrlWithQueryParams,
	type StageResponse,
	type QuizData,
	type Question,
};
