import { wretch } from "~/lib/external/wretch";

import { buildUrl } from "../../utilities";

import { pathwaysSchema } from "./pathways.schema";

const getUrl = () => buildUrl("pathways", 1);

const getPathways = () =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl())
		.json(pathwaysSchema.parseAsync);

type GetPathwaysResponse = Awaited<ReturnType<typeof getPathways>>;

export { getPathways, getUrl, type GetPathwaysResponse };
