import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	queryOptions,
	useQuery as rqUseQuery,
	useSuspenseQuery as rqUseSuspenseQuery,
	type UseQueryResult,
	type UseQueryOptions,
} from "@tanstack/react-query";

import { PostType } from "~/enums";
import { type Parent } from "~/types/application";

import { getPathway, getUrl, type GetPathwayResponse } from "./pathway.api";

type PathwayParent = Parent<PostType.PATHWAY, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [pathway: PathwayParent];

const getQueryKey = (id: number) => ["learning", "pathway", { id }] as const;

const getBaseQueryOptions = <TData = Extract<GetPathwayResponse, { type: PostType.PATHWAY }>>(id: number) =>
	queryOptions<GetPathwayResponse, Error, TData>({
		queryFn: () => getPathway(id),
		queryKey: getQueryKey(id),
	});

const useQuery = <TData = Extract<GetPathwayResponse, { type: PostType.PATHWAY }>>(
	id: number,
	options?: Pick<UseQueryOptions<GetPathwayResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	rqUseQuery<GetPathwayResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
	});

const useSuspenseQuery = <TData = Extract<GetPathwayResponse, { type: PostType.PATHWAY }>>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetPathwayResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	rqUseSuspenseQuery<GetPathwayResponse, Error, TData>({
		...getBaseQueryOptions(id),
		...options,
	});

const getParent = (id: number) =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		id,
		type: PostType.PATHWAY,
	}) satisfies PathwayParent;

export {
	useQuery as usePathwayQuery,
	useSuspenseQuery as usePathwaySuspenseQuery,
	getBaseQueryOptions as getPathwayBaseQueryOptions,
	getQueryKey as getPathwayQueryKey,
	getParent as getPathwayParent,
	getUrl as getPathwayUrl,
	type Tuple as PathwayTuple,
};
