import { wretch } from "~/lib/external/wretch";

import { buildUrlWithQuery, type BuildUrlWithQueryParams } from "../../utilities";

import { type StageResponse, stageSchema } from "./stage.schema";

const getUrlQuery = (stageId: number, params?: BuildUrlWithQueryParams<{ missionId: number | undefined }>) =>
	buildUrlWithQuery(`stage/${stageId}`, 1, params);

const getStage = (stageId: number, missionId?: number): Promise<StageResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.query({ missionId })
		.get(getUrlQuery(stageId))
		.json(stageSchema.parseAsync);

type GetStageResponse = Awaited<ReturnType<typeof getStage>>;

export { getStage, getUrlQuery as getStageUrlWithQueryParams, type GetStageResponse };
