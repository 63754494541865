/**
 * Generates a hash for the given input
 * @param input The input string to be hashed
 * @returns The hash value
 * @example
 * generateHash('user123');
 * // Output: "03ac674216f3e15c761ee1a5e255f067953623c8b388b4459e13f978d7c846f4"
 */
export const generateHash = async (input: string | number) => {
	const saltedInput = new TextEncoder().encode(input.toString());
	const hashBuffer = await crypto.subtle.digest("SHA-256", saltedInput);
	const hashArray = Array.from(new Uint8Array(hashBuffer));

	return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
};
