import { createRoute, lazyRouteComponent } from "@tanstack/react-router";

import { LeaderboardKey } from "~/enums";
import { getAwardsBaseQueryOptions } from "~/lib/api/awards";
import { getAllLeadersBaseQueryOptions } from "~/lib/api/leaderboard/all-leaders";
import { getTopLeadersBaseQueryOptions } from "~/lib/api/leaderboard/top-leaders";
import { getMissionsBaseQueryOptions } from "~/lib/api/learning/missions";
import { getPathwaysBaseQueryOptions } from "~/lib/api/learning/pathways";
import { getActivitiesBaseQueryOptions } from "~/lib/api/profile/activities";
import { getBookmarksQueryOptions } from "~/lib/api/profile/bookmarks";
import { getCompletedLearningQueryOptions } from "~/lib/api/profile/completed-learning";
import { getResourceCategoriesBaseQueryOptions } from "~/lib/api/resources/categories";
import { getLatestResourcesBaseQueryOptions } from "~/lib/api/resources/latest-resources";

import { privateRoute } from "./private";

export const applicationLayout = createRoute({
	component: lazyRouteComponent(() => import("~/layouts/Main"), "MainLayout"),
	getParentRoute: () => privateRoute,
	id: "application-layout",
	loader: ({ context }) => {
		// If we get here, we must have a valid tenant and an authenticated user, that has accepted any terms and conditions or privacy policies
		// We can now prefetch all the stattic data we need for the application
		Promise.allSettled([
			context.queryClient.prefetchQuery(getActivitiesBaseQueryOptions()),
			context.queryClient.prefetchQuery(getAllLeadersBaseQueryOptions(LeaderboardKey.MONTHLY)),
			context.queryClient.prefetchQuery(getAllLeadersBaseQueryOptions(LeaderboardKey.OVERALL)),
			context.queryClient.prefetchQuery(getTopLeadersBaseQueryOptions(LeaderboardKey.MONTHLY)),
			context.queryClient.prefetchQuery(getTopLeadersBaseQueryOptions(LeaderboardKey.OVERALL)),
			context.queryClient.prefetchQuery(getAwardsBaseQueryOptions()),
			context.queryClient.prefetchQuery(getBookmarksQueryOptions()),
			context.queryClient.prefetchQuery(getMissionsBaseQueryOptions()),
			context.queryClient.prefetchQuery(getCompletedLearningQueryOptions()),
			context.queryClient.prefetchQuery(getPathwaysBaseQueryOptions()),
			context.queryClient.prefetchQuery(getResourceCategoriesBaseQueryOptions()),
			context.queryClient.prefetchQuery(getLatestResourcesBaseQueryOptions()),
		]);
	},
});
