import { lazy } from "react";

export const AWARDS_TO_DISPLAY = 6;

export const AWARD_MAP = {
	"alert-circle": lazy(() =>
		import("~/assets/images/awards/alert-circle.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"alert-diamond": lazy(() =>
		import("~/assets/images/awards/alert-diamond.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"alert-triangle": lazy(() =>
		import("~/assets/images/awards/alert-triangle.svg").then((module) => ({ default: module.ReactComponent })),
	),
	bandage: lazy(() =>
		import("~/assets/images/awards/bandage.svg").then((module) => ({ default: module.ReactComponent })),
	),
	breakage: lazy(() =>
		import("~/assets/images/awards/breakage.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"building-fire": lazy(() =>
		import("~/assets/images/awards/building-fire.svg").then((module) => ({ default: module.ReactComponent })),
	),
	car: lazy(() => import("~/assets/images/awards/car.svg").then((module) => ({ default: module.ReactComponent }))),
	"car-accident": lazy(() =>
		import("~/assets/images/awards/car-accident.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"car-fault": lazy(() =>
		import("~/assets/images/awards/car-fault.svg").then((module) => ({ default: module.ReactComponent })),
	),
	checklist: lazy(() =>
		import("~/assets/images/awards/checklist.svg").then((module) => ({ default: module.ReactComponent })),
	),
	chemical: lazy(() =>
		import("~/assets/images/awards/chemical.svg").then((module) => ({ default: module.ReactComponent })),
	),
	configuration: lazy(() =>
		import("~/assets/images/awards/configuration.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"construction-female": lazy(() =>
		import("~/assets/images/awards/construction-female.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"construction-male": lazy(() =>
		import("~/assets/images/awards/construction-male.svg").then((module) => ({ default: module.ReactComponent })),
	),
	damage: lazy(() =>
		import("~/assets/images/awards/damage.svg").then((module) => ({ default: module.ReactComponent })),
	),
	default: lazy(() =>
		import("~/assets/images/awards/default.svg").then((module) => ({ default: module.ReactComponent })),
	),
	deforestation: lazy(() =>
		import("~/assets/images/awards/deforestation.svg").then((module) => ({ default: module.ReactComponent })),
	),
	delivery: lazy(() =>
		import("~/assets/images/awards/delivery.svg").then((module) => ({ default: module.ReactComponent })),
	),
	ecology: lazy(() =>
		import("~/assets/images/awards/ecology.svg").then((module) => ({ default: module.ReactComponent })),
	),
	environment: lazy(() =>
		import("~/assets/images/awards/environment.svg").then((module) => ({ default: module.ReactComponent })),
	),
	extinguisher: lazy(() =>
		import("~/assets/images/awards/extinguisher.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"fall-hazard": lazy(() =>
		import("~/assets/images/awards/fall-hazard.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"find-settings": lazy(() =>
		import("~/assets/images/awards/find-settings.svg").then((module) => ({ default: module.ReactComponent })),
	),
	fire: lazy(() => import("~/assets/images/awards/fire.svg").then((module) => ({ default: module.ReactComponent }))),
	"fire-escape": lazy(() =>
		import("~/assets/images/awards/fire-escape.svg").then((module) => ({ default: module.ReactComponent })),
	),
	forklift: lazy(() =>
		import("~/assets/images/awards/forklift.svg").then((module) => ({ default: module.ReactComponent })),
	),
	gardening: lazy(() =>
		import("~/assets/images/awards/gardening.svg").then((module) => ({ default: module.ReactComponent })),
	),
	gloves: lazy(() =>
		import("~/assets/images/awards/gloves.svg").then((module) => ({ default: module.ReactComponent })),
	),
	goggles: lazy(() =>
		import("~/assets/images/awards/goggles.svg").then((module) => ({ default: module.ReactComponent })),
	),
	growth: lazy(() =>
		import("~/assets/images/awards/growth.svg").then((module) => ({ default: module.ReactComponent })),
	),
	hammer: lazy(() =>
		import("~/assets/images/awards/hammer.svg").then((module) => ({ default: module.ReactComponent })),
	),
	hardhat: lazy(() =>
		import("~/assets/images/awards/hardhat.svg").then((module) => ({ default: module.ReactComponent })),
	),
	harmful: lazy(() =>
		import("~/assets/images/awards/harmful.svg").then((module) => ({ default: module.ReactComponent })),
	),
	hospital: lazy(() =>
		import("~/assets/images/awards/hospital.svg").then((module) => ({ default: module.ReactComponent })),
	),
	idea: lazy(() => import("~/assets/images/awards/idea.svg").then((module) => ({ default: module.ReactComponent }))),
	injured: lazy(() =>
		import("~/assets/images/awards/injured.svg").then((module) => ({ default: module.ReactComponent })),
	),
	injury: lazy(() =>
		import("~/assets/images/awards/injured.svg").then((module) => ({ default: module.ReactComponent })),
	),
	ladder: lazy(() =>
		import("~/assets/images/awards/ladder.svg").then((module) => ({ default: module.ReactComponent })),
	),
	location: lazy(() =>
		import("~/assets/images/awards/location.svg").then((module) => ({ default: module.ReactComponent })),
	),
	office: lazy(() =>
		import("~/assets/images/awards/office.svg").then((module) => ({ default: module.ReactComponent })),
	),
	poison: lazy(() =>
		import("~/assets/images/awards/poison.svg").then((module) => ({ default: module.ReactComponent })),
	),
	pollution: lazy(() =>
		import("~/assets/images/awards/pollution.svg").then((module) => ({ default: module.ReactComponent })),
	),
	question: lazy(() =>
		import("~/assets/images/awards/question.svg").then((module) => ({ default: module.ReactComponent })),
	),
	"safety-vest": lazy(() =>
		import("~/assets/images/awards/safety-vest.svg").then((module) => ({ default: module.ReactComponent })),
	),
	thinking: lazy(() =>
		import("~/assets/images/awards/thinking.svg").then((module) => ({ default: module.ReactComponent })),
	),
	tools: lazy(() =>
		import("~/assets/images/awards/tools.svg").then((module) => ({ default: module.ReactComponent })),
	),
	tree: lazy(() => import("~/assets/images/awards/tree.svg").then((module) => ({ default: module.ReactComponent }))),
	treehouse: lazy(() =>
		import("~/assets/images/awards/treehouse.svg").then((module) => ({ default: module.ReactComponent })),
	),
	view: lazy(() => import("~/assets/images/awards/view.svg").then((module) => ({ default: module.ReactComponent }))),
	weather: lazy(() =>
		import("~/assets/images/awards/weather.svg").then((module) => ({ default: module.ReactComponent })),
	),
	wireless: lazy(() =>
		import("~/assets/images/awards/wireless.svg").then((module) => ({ default: module.ReactComponent })),
	),
	work: lazy(() => import("~/assets/images/awards/work.svg").then((module) => ({ default: module.ReactComponent }))),
	wrench: lazy(() =>
		import("~/assets/images/awards/wrench.svg").then((module) => ({ default: module.ReactComponent })),
	),
};

export type AwardIcon = keyof typeof AWARD_MAP;
