import { buildUrl } from "~/lib/api/utilities";
import { wretch } from "~/lib/external/wretch";

import { missionSchema } from "./mission.schema";

const getUrl = (id: number) => buildUrl(`mission/${id}`, 1);

const getMission = (id: number) =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.get(getUrl(id))
		.json(missionSchema.parseAsync);

type GetMissionResponse = Awaited<ReturnType<typeof getMission>>;

export { getMission, getUrl, type GetMissionResponse };
