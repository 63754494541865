import { wretch } from "~/lib/external/wretch";
import { type MutationSuccessResponse, mutationSuccessResponse } from "~/lib/schemas/mutation-success";

import { buildUrl } from "../../utilities";
import { type SortDirection } from "../types";

import { bookmarksSchema, type Bookmark, type BookmarksResponse } from "./bookmarks.schema";

const getUrl = () => buildUrl(`profile/bookmarks`, 1);
const postUrl = () => buildUrl(`profile/bookmark`, 1);
const deleteUrl = (bookmarkId: number) => buildUrl(`profile/bookmark/${bookmarkId}`, 1);

const getBookmarks = async (sort: SortDirection): Promise<BookmarksResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.query({ sort })
		.get(getUrl())
		.json(bookmarksSchema.parseAsync);

type GetBookmarksResponse = Awaited<ReturnType<typeof getBookmarks>>;

const postBookmark = async ({ id, ...data }: Bookmark): Promise<MutationSuccessResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.post(
			{
				post_id: id,
				...data,
			},
			postUrl(),
		)
		.json(mutationSuccessResponse.parse);

type PostBookmarkResponse = Awaited<ReturnType<typeof postBookmark>>;

const deleteBookmark = async (id: number): Promise<MutationSuccessResponse> =>
	wretch().delete(deleteUrl(id)).json(mutationSuccessResponse.parse);

type DeleteBookmarkResponse = Awaited<ReturnType<typeof deleteBookmark>>;

export {
	getBookmarks,
	postBookmark,
	deleteBookmark,
	getUrl,
	deleteUrl,
	type GetBookmarksResponse,
	type PostBookmarkResponse,
	type DeleteBookmarkResponse,
};
