import { type InitialiseReturn, NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { setCookie, deleteCookie } from "@evotix/napier-ui-common-native-bridge/utilities";
import { jwtDecode, type JwtPayload } from "jwt-decode";

import { getNativeHeartbeat } from "~/lib/api/authentication/heartbeat/heartbeat.api";
import { TOKEN_COOKIE_NAME } from "~/lib/constants/application";
import { getWindowHostDomain } from "~/utilities/get-window-host-domain";

type CustomJwtPayload = JwtPayload & {
	sub?: { uid: number };
};

export const initialiseNativeAppAuthentication = async (data: InitialiseReturn) => {
	if (data.platform !== "native" || !data.persistedData) {
		/**
		 * Do nothing if we're not running in a native environment
		 * If we don't have any persisted data, we can't do anything, the user will have to sign in again
		 */
		return;
	}

	const { token, tenant } = data.persistedData;

	try {
		/**
		 * When offline the bridge will always return a success response
		 */
		const heartbeatResponse = await getNativeHeartbeat(tenant, token, async (error) => {
			await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });

			return error;
		});

		if (!heartbeatResponse.success) {
			/**
			 * The request was unsuccessful, so we assume the user is not authenticated
			 */
			await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });
			return;
		}

		// We don't set an expiry here, so the cookie is set as a session cookie, this enables us to have a cookie that works whilst offline
		await setCookie(TOKEN_COOKIE_NAME, token, { domain: `.${getWindowHostDomain()}` });
	} catch (error) {
		// The request errored, so we assume the user is not authenticated
		await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });
	}
};

export const initialiseNativeAppSSOAuthentication = async (_url: string, cookies: Record<string, string>) => {
	const token = cookies[TOKEN_COOKIE_NAME];

	if (!token) {
		return;
	}

	try {
		const { sub } = jwtDecode<CustomJwtPayload>(token);

		// We don't set an expiry here, so the cookie is set as a session cookie, this enables us to have a cookie that works whilst offline
		await setCookie(TOKEN_COOKIE_NAME, token, { domain: `.${getWindowHostDomain()}` });

		await NapierBridgeCommon.setActiveUser({ token, userId: sub!.uid.toString() });
	} catch (error) {
		// Swallow the error, as we're not able to decode the token, there's nothing we can do, at this point, we have to assume the user is not authenticated.
	}
};

export const ensureCookieDeletion = async () => {
	await deleteCookie(TOKEN_COOKIE_NAME);
};
