import arAE from "./locales/ar-AE.json";
import csCZ from "./locales/cs-CZ.json";
import daDK from "./locales/da-DK.json";
import deDE from "./locales/de-DE.json";
import enGB from "./locales/en-GB.json";
import enUS from "./locales/en-US.json";
import esES from "./locales/es-ES.json";
import esUS from "./locales/es-US.json";
import fiFI from "./locales/fi-FI.json";
import frCA from "./locales/fr-CA.json";
import frFR from "./locales/fr-FR.json";
import hiIN from "./locales/hi-IN.json";
import hrHR from "./locales/hr-HR.json";
import huHU from "./locales/hu-HU.json";
import itIT from "./locales/it-IT.json";
import jaJP from "./locales/ja-JP.json";
import koKR from "./locales/ko-KR.json";
import ltLT from "./locales/lt-LT.json";
import msMY from "./locales/ms-MY.json";
import nbNO from "./locales/nb-NO.json";
import nlNL from "./locales/nl-NL.json";
import paIN from "./locales/pa-IN.json";
import pePE from "./locales/pe-PE.json";
import plPL from "./locales/pl-PL.json";
import ptBR from "./locales/pt-BR.json";
import ptPT from "./locales/pt-PT.json";
import roRO from "./locales/ro-RO.json";
import ruRU from "./locales/ru-RU.json";
import skSK from "./locales/sk-SK.json";
import slSI from "./locales/sl-SI.json";
import svSE from "./locales/sv-SE.json";
import ta from "./locales/ta.json";
import viVN from "./locales/vi-VN.json";
import zhHANS from "./locales/zh-HANS.json";
import arAEZod from "./zod/ar-AE.json";
import csCZZod from "./zod/cs-CZ.json";
import daDKZod from "./zod/da-DK.json";
import deDEZod from "./zod/de-DE.json";
import enGBZod from "./zod/en-GB.json";
import enUSZod from "./zod/en-US.json";
import esESZod from "./zod/es-ES.json";
import esUSZod from "./zod/es-US.json";
import fiFIZod from "./zod/fi-FI.json";
import frCAZod from "./zod/fr-CA.json";
import frFRZod from "./zod/fr-FR.json";
import hiINZod from "./zod/hi-IN.json";
import hrHRZod from "./zod/hr-HR.json";
import huHUZod from "./zod/hu-HU.json";
import itITZod from "./zod/it-IT.json";
import jaJPZod from "./zod/ja-JP.json";
import koKRZod from "./zod/ko-KR.json";
import ltLTZod from "./zod/lt-LT.json";
import msMYZod from "./zod/ms-MY.json";
import nbNOZod from "./zod/nb-NO.json";
import nlNLZod from "./zod/nl-NL.json";
import paINZod from "./zod/pa-IN.json";
import pePEZod from "./zod/pe-PE.json";
import plPLZod from "./zod/pl-PL.json";
import ptBRZod from "./zod/pt-BR.json";
import ptPTZod from "./zod/pt-PT.json";
import roROZod from "./zod/ro-RO.json";
import ruRUZod from "./zod/ru-RU.json";
import skSKZod from "./zod/sk-SK.json";
import slSIZod from "./zod/sl-SI.json";
import svSEZod from "./zod/sv-SE.json";
import taZod from "./zod/ta.json";
import viVNZod from "./zod/vi-VN.json";
import zhHANSZod from "./zod/zh-HANS.json";

export const resources = {
	"ar-AE": {
		translation: arAE,
		zod: arAEZod,
	},
	"cs-CZ": {
		translation: csCZ,
		zod: csCZZod,
	},
	"da-DK": {
		translation: daDK,
		zod: daDKZod,
	},
	"de-DE": {
		translation: deDE,
		zod: deDEZod,
	},
	"en-GB": {
		translation: enGB,
		zod: enGBZod,
	},
	"en-US": {
		translation: enUS,
		zod: enUSZod,
	},
	"es-ES": {
		translation: esES,
		zod: esESZod,
	},
	"es-US": {
		translation: esUS,
		zod: esUSZod,
	},
	"fi-FI": {
		translation: fiFI,
		zod: fiFIZod,
	},
	"fr-CA": {
		translation: frCA,
		zod: frCAZod,
	},
	"fr-FR": {
		translation: frFR,
		zod: frFRZod,
	},
	"hi-IN": {
		translation: hiIN,
		zod: hiINZod,
	},
	"hr-HR": {
		translation: hrHR,
		zod: hrHRZod,
	},
	"hu-HU": {
		translation: huHU,
		zod: huHUZod,
	},
	"it-IT": {
		translation: itIT,
		zod: itITZod,
	},
	"ja-JP": {
		translation: jaJP,
		zod: jaJPZod,
	},
	"ko-KR": {
		translation: koKR,
		zod: koKRZod,
	},
	"lt-LT": {
		translation: ltLT,
		zod: ltLTZod,
	},
	"ms-MY": {
		translation: msMY,
		zod: msMYZod,
	},
	"nb-NO": {
		translation: nbNO,
		zod: nbNOZod,
	},
	"nl-NL": {
		translation: nlNL,
		zod: nlNLZod,
	},
	"pa-Guru-IN": {
		translation: paIN,
		zod: paINZod,
	},
	"pe-PE": {
		translation: pePE,
		zod: pePEZod,
	},
	"pl-PL": {
		translation: plPL,
		zod: plPLZod,
	},
	"pt-BR": {
		translation: ptBR,
		zod: ptBRZod,
	},
	"pt-PT": {
		translation: ptPT,
		zod: ptPTZod,
	},
	"ro-RO": {
		translation: roRO,
		zod: roROZod,
	},
	"ru-RU": {
		translation: ruRU,
		zod: ruRUZod,
	},
	"sk-SK": {
		translation: skSK,
		zod: skSKZod,
	},
	"sl-SI": {
		translation: slSI,
		zod: slSIZod,
	},
	"sv-SE": {
		translation: svSE,
		zod: svSEZod,
	},
	ta: {
		translation: ta,
		zod: taZod,
	},
	"vi-VN": {
		translation: viVN,
		zod: viVNZod,
	},
	"zh-Hans": {
		translation: zhHANS,
		zod: zhHANSZod,
	},
} as const;
