import { z } from "zod";

import { PostType } from "~/enums";

import { contentBlockSchema } from "../../learning/stage/stage.schema";
import { featuredMediaSchema } from "../../shared.schema";

const resourceSchema = z.object({
	commentsAllowed: z.boolean().catch(true),
	content: z.array(contentBlockSchema),
	featuredMedia: featuredMediaSchema.nullable().optional(),
	id: z.number(),
	title: z.string(),
	type: z.literal(PostType.RESOURCE),
});

type ResourceResponse = z.infer<typeof resourceSchema>;

export { resourceSchema, type ResourceResponse };
