import { wretch } from "~/lib/external/wretch";

import { buildUrl } from "../utilities";

import { type ProfileResponse, profileSchema } from "./profile.schema";

const getUrl = () => buildUrl("profile", 1);

const postUrl = () => buildUrl("profile/avatar", 1);

const getProfile = (): Promise<ProfileResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl())
		.json(profileSchema.parseAsync);

type GetProfileResponse = Awaited<ReturnType<typeof getProfile>>;

const postUploadProfileAvatar = (file: File) =>
	wretch()
		.url(postUrl())
		.formData({
			file,
		})
		.post()
		.json(profileSchema.parseAsync);

type PostUploadProfileAvatarResponse = Awaited<ReturnType<typeof postUploadProfileAvatar>>;

export { getProfile, postUploadProfileAvatar, type GetProfileResponse, type PostUploadProfileAvatarResponse };
