import { type UseQueryResult, queryOptions, useQuery } from "@tanstack/react-query";

import { LIVE_QUERY_STALE_TIME } from "~/lib/constants/application";

import { type GetUserLanguageResponse, getUserLanguage } from "./user-language.api";

const getQueryKey = (userId: number) => ["user", "language", { userId }] as const;

const getBaseQueryOptions = <TData = GetUserLanguageResponse>(userId: number) =>
	queryOptions<GetUserLanguageResponse, Error, TData, ReturnType<typeof getQueryKey>>({
		queryFn: () => getUserLanguage(userId),
		queryKey: getQueryKey(userId),
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		staleTime: LIVE_QUERY_STALE_TIME,
	});

const useUserLanguageQuery = <TData = GetUserLanguageResponse>(userId: number): UseQueryResult<TData, Error> =>
	useQuery<GetUserLanguageResponse, Error, TData, ReturnType<typeof getQueryKey>>(getBaseQueryOptions(userId));

export {
	useUserLanguageQuery,
	getQueryKey as getUserLanguageQueryKey,
	getBaseQueryOptions as getUserLanguageBaseQueryOptions,
};
