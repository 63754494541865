import { isNative } from "@evotix/napier-ui-common-native-bridge/utilities";
import { QueryClient } from "@tanstack/react-query";

const networkMode = isNative() ? "offlineFirst" : "online";

export const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {
			networkMode,
		},
		queries: {
			gcTime: 1000 * 60 * 60 * 1, // 1 hour
			networkMode,
			refetchOnMount: false,
			refetchOnReconnect: true,
			refetchOnWindowFocus: false,
			staleTime: Infinity,
		},
	},
});
