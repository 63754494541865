import {
	useQuery,
	useSuspenseQuery,
	type UseQueryOptions,
	type UseSuspenseQueryOptions,
	type UseQueryResult,
	type UseSuspenseQueryResult,
	queryOptions,
} from "@tanstack/react-query";

import { type GetSiteConfigResponse, getSiteConfig } from "./site-config.api";
import { type SiteConfigResponse } from "./site-config.schema";

const getQueryKey = () => ["site-config"] as const;

const getBaseQueryOptions = <TData = GetSiteConfigResponse>() =>
	queryOptions<GetSiteConfigResponse, Error, TData>({
		queryFn: getSiteConfig,
		queryKey: getQueryKey(),
		retry: false,
	});

const useSiteConfigQuery = <TData = GetSiteConfigResponse>(
	options?: Pick<UseQueryOptions<GetSiteConfigResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	useQuery<GetSiteConfigResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

const useSiteConfigSuspenseQuery = <TData = GetSiteConfigResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetSiteConfigResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> => {
	return useSuspenseQuery<GetSiteConfigResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});
};

export {
	useSiteConfigQuery,
	useSiteConfigSuspenseQuery,
	getBaseQueryOptions as getSiteConfigBaseQueryOptions,
	getQueryKey as getSiteConfigQueryKey,
	type SiteConfigResponse,
};
