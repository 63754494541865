import i18next, { changeLanguage } from "i18next";

import { type Language } from "~/lib/constants/language";
import { dayjs } from "~/lib/external/day-js";
import { queryClient } from "~/lib/queryClient";
import { splitString } from "~/utilities/split-string";

import { getLanguageStorageKey } from "../get-language-storage-key";

/**
 * Switch i18n language and save language to local storage against the current user
 * @param key - An iso lanaguage code
 * @example await switchLanguage("en-gb", 1234);
 */
export const switchLanguage = async (key: Language, userId?: number) => {
	// Handle the case we we call this method with the same languageKey the is already set
	if (i18next.language === key) return;

	await changeLanguage(key);
	dayjs.locale(key);

	document.documentElement.lang = splitString(key, "-")[0];

	// Make sure we cancel any ongoing queries and invalidate all queries
	/**
	 * TODO: rethink this logic, as it shuold only invalidate queries that are language dependent (learning/resources/completed-learning/bookmarks)
	 * and not invalide application level queries (profile/site-config/awards...)*/
	await queryClient.cancelQueries({ type: "all" });
	await queryClient.invalidateQueries({ type: "all" });

	if (!userId) return;

	try {
		localStorage.setItem(await getLanguageStorageKey(userId), key);
	} catch (e) {
		//TODO: Handle this properly
		// eslint-disable-next-line no-console -- will be removed once we have proper error handling
		console.error(e);
	}
};
