/**
 * Splits a string using the specified separator.
 * @param {string} str The input string to split.
 * @param {string} separator The separator to use for splitting the string.
 * @returns {string[]} An array containing the substrings after splitting the input string.
 * If the string cannot be split using the separator, returns an array containing the original string.
 * @example
 * const result1 = splitString("hello-world", "-"); // Returns: ["hello", "world"]
 * const result2 = splitString("hello", "-"); // Returns: ["hello"]
 */
export const splitString = <T extends string, S extends string>(
	str: T,
	separator: S,
): T extends `${infer A}${S}${infer B}` ? [A, B] : [T] => {
	return str.split(separator) as never;
};
