import { z } from "zod";

import { PostType } from "~/enums";

import { baseLearningGroupChild, baseLearningGroupSchema } from "../../shared.schema";

export const levelSchema = baseLearningGroupSchema.extend({
	children: z.array(
		baseLearningGroupChild.extend({
			awardIds: z.array(z.number()).nullable().optional(),
			dueDate: z.string().datetime({ offset: true }).nullable(),
			type: z.literal(PostType.MISSION),
		}),
	),
	type: z.literal(PostType.LEVEL),
});

export type LevelResponse = z.infer<typeof levelSchema>;
